<template>
  <div class="product_jeim_content">
    <div class="box_top">
      <Header class="page_hea" />
      <div class="top_tlite">
        <p class="title">
          JEIM
        </p>
        <p class="subtitle">
          让沟通变的更高效!
        </p>
        <div
          class="btn"
          @click="openqq"
        >
          立即咨询
        </div>
      </div>
      <div class="top_img">
        <img
          v-lazy="jeim_bg"
          alt
        >
      </div>
    </div>
    <div class="page_content">
      <div class="section">
        <p class="title">
          双平台高效互通
        </p>
        <p class="subtitle">
          JEIM，可与JEPaaS一同使用，实现业务高效打通。
        </p>
        <div class="content_box">
          <div class="video_wrp">
            <video
              ref="video"
              :src="videoUrl"
              preload="auto"
              controls="controls"
              class="video"
            />
            <div
              v-show="videoMask == 'pause'"
              class="video_mask"
              @click="videoPlay"
            >
              <img
                :src="play"
                alt
              >
            </div>
          </div>
        </div>
      </div>
      <div class="section core">
        <p class="title">
          公司为什么需要企业即时通讯？
        </p>
        <p class="subtitle">
          快速解决办公途中的沟通协同问题、缩短企业内部沟通距离、提高企业工作效率是企业即时通讯存在的真正意义。
        </p>
        <div class="content_box">
          <img
            :src="im_1"
            alt
            style="width: 100%;"
          >
        </div>
      </div>
      <div class="section">
        <p class="title">
          JEIM核心功能
        </p>
        <div class="content_box">
          <div class="tablist">
            <div
              v-for="(item, index) in tablist"
              :key="index"
              :class="`${tabActive.title === item.title ? 'active' : ''}`"
              @click="handleTab(item)"
            >
              {{ item.title }}
            </div>
          </div>
          <div class="tabcontent">
            <div class="otherinfo">
              <p class="title">
                {{ tabActive.title }}
              </p>
              <p class="subtitle">
                {{ tabActive.subtitle }}
              </p>
              <btn
                class="ljxq"
                :content="'了解详情'"
                @click="openBut"
              />
            </div>
            <img
              :src="tabActive.img"
              class="img"
              alt
            >
          </div>
        </div>
      </div>
      <div class="section linear">
        <p class="title">
          特点与优势
        </p>
        <div class="content_box">
          <div class="speciallist">
            <div
              v-for="(item, index) in speciallist"
              :key="index"
              class="items"
            >
              <img
                :src="item.img"
                alt
              >
              <p class="title">
                {{ item.title }}
              </p>
              <p class="subtitle">
                {{ item.subtitle }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import jeim_bg from '@/assets/imgs/product/jeim/im_bg.png';
import im_1 from '@/assets/imgs/product/jeim/im_1.png';
import special_1 from '@/assets/imgs/product/jeim/special_1.png';
import special_2 from '@/assets/imgs/product/jeim/special_2.png';
import special_3 from '@/assets/imgs/product/jeim/special_3.png';
import special_4 from '@/assets/imgs/product/jeim/special_4.png';
import special_5 from '@/assets/imgs/product/jeim/special_5.png';
import tab_1 from '@/assets/imgs/product/jeim/tab_1.png';
import tab_2 from '@/assets/imgs/product/jeim/tab_2.png';
import tab_3 from '@/assets/imgs/product/jeim/tab_3.png';
import tab_4 from '@/assets/imgs/product/jeim/tab_4.png';
import btn from '@/components/btn.vue';
import videoUrl from '@/assets/imgs/video/jeim.mp4';
import play from '@/assets/imgs/video/play.png';

export default {
  name: 'Jeim',
  components: {
    Header,
    Footer,
    btn,
  },
  data() {
    return {
      play,
      videoUrl,
      jeim_bg,
      im_1,
      speciallist: [
        {
          title: '稳定',
          subtitle: '通过全面功能及安全性测试',
          img: special_1,
        },
        {
          title: '大并发',
          subtitle: '高并发压力（OPC）负载测试',
          img: special_2,
        },
        {
          title: '开放',
          subtitle: '通过API接口，可集成企业现有OA,ERP,CRM等办公化系统',
          img: special_3,
        },
        {
          title: '专属',
          subtitle: '可为企业单独构建可靠安全的沟通平台',
          img: special_4,
        },
        {
          title: '效果',
          subtitle: '员工扯皮减少100%，员工效率提升300%',
          img: special_5,
        },
      ],
      tablist: [
        {
          title: '企业好友沟通',
          subtitle:
            '快速解决办公途中的沟通协同问题、缩短企业内部沟通距离，提高企业工作效率。',
          img: tab_1,
        },
        {
          title: '企业群组沟通',
          subtitle: '快速实现企业群组沟通、工作讨论等，提高企业协同办工效率。',
          img: tab_2,
        },
        {
          title: '推送服务',
          subtitle:
            '支持对接APNS（苹果）、Google、华为、小米、OPPO、VIVO、魅族等各大消息推送平台。',
          img: tab_3,
        },
        {
          title: '对接微信、钉钉',
          subtitle: '对接服务号，钉公告，实时打通。',
          img: tab_4,
        },
      ],
      tabActive: {},
      videoMask: 'pause',
    };
  },
  mounted() {
    const that = this;
    this.tabActive = this.tablist[0];
    const myVideo = document.getElementsByClassName('video')[0];
    myVideo.addEventListener('play', () => {
      that.$set(that, 'videoMask', 'play');
    });

    myVideo.addEventListener('pause', () => {
      that.$set(that, 'videoMask', 'pause');
    });
  },
  methods: {
    handleTab(item) {
      this.tabActive = item;
    },
    openBut() {
      const self = this;
      self.$store.commit('addActChange', false);
      self.$store.commit('moreActChange', false);
      self.$store.commit('winActChange', true);
    },
    openqq() {
      window.open('http://shang.qq.com/wpa/qunwpa?idkey=3d0bc04e6a1e43055b8e69fd8324c477a45fa1cba57cfcd03dc95ba61427c52c');
    },
    videoPlay() {
      this.$refs.video.play();
    },
  },
};
</script>

<style lang="less" scoped>
.product_jeim_content {
  .box_top {
    width: 100%;
    height: 600px;
    background: linear-gradient(
      270deg,
      rgba(73, 0, 134, 1) 0%,
      rgba(248, 83, 77, 1) 99%
    );
    display: flex;
    justify-content: space-between;
    .top_tlite {
      padding: 160px 0 0 195px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      > p {
        padding-left: 180px;
      }
      > .title {
        font-size: 60px;
        margin: 0;
        text-align: left;
      }
      > .subtitle {
        font-size: 60px;
        margin: 20px 0 70px;
        // margin-top: 30px
      }
      > .btn {
        box-sizing: border-box;
        text-align: center;
        margin-left: 180px;
        // margin-top: 80px;
        width: 200px;
        height: 60px;
        line-height: 60px;
        border: 2px solid rgba(255, 255, 255, 1);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0.5) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        border-radius: 6px;
        cursor: pointer;
      }
    }
    .top_img {
      display: flex;
      align-items: flex-end;
      img {
        width: 794px;
        height: 515px;
        position: relative;
        left: -100px;
      }
    }
  }
  .page_content {
    .section {
      text-align: center;
      margin: 0 auto;
      padding: 120px 0 100px;
      &.core {
        background: #f7fbff;
      }
      &.linear {
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 239, 235, 1) 100%
        );
      }
      .title {
        font-size: 40px;
        color: #000000;
        text-align: center;
        font-weight: bold;
        margin-bottom: 80px;
      }
      .subtitle {
        font-size: 20px;
        color: #303336;
        text-align: center;
        margin: 80px auto 100px;
      }
      .content_box {
        width: 1200px;
        margin: 0 auto;
        .video_wrp {
          width: 900px;
          height: 506px;
          margin: 0 auto;
          position: relative;
          .video {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 900px;
            height: 506px;
            z-index: 1;
            object-fit: contain;
          }
          .video_mask {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;
            background: rgba(0, 0, 0, 0.4);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            > img {
              width: 80px;
            }
          }
        }
        .speciallist {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          flex-wrap: wrap;
          > div {
            width: 33%;
            margin-bottom: 60px;
            > img {
              width: 200px;
              height: 200px;
            }
            > p {
              text-align: center;
              color: #333333;
              font-size: 18px;
              margin: 0 auto;
              width: 250px;
            }
            .title {
              font-weight: bold;
              font-size: 24px;
              margin: 30px auto 20px;
            }
          }
        }
        .tablist {
          display: flex;
          justify-content: center;
          align-items: center;
          > div {
            width: 25%;
            background: #ececec;
            color: #303336;
            font-size: 20px;
            height: 60px;
            line-height: 60px;
            cursor: pointer;
            &.active,
            &:hover {
              background: #ff3041;
              color: #ffffff;
              font-weight: bold;
            }
          }
        }
        .tabcontent {
          margin-top: 90px;
          display: flex;
          .otherinfo {
            flex: 1;
            > p {
              text-align: left;
              width: 340px;
              font-size: 18px;
              color: #303336;
            }
            .title {
              margin: 50px 0 40px;
              font-size: 24px;
              color: #333333;
            }
            .subtitle {
              margin: 0;
              height: 80px;
            }
            .ljxq {
              border-radius: 6px;
              margin-left: 0;
            }
          }
          .img {
            width: 750px;
          }
        }
      }
    }
  }
}
</style>
